export class EmailFormController {

    static #EMAIL_PATTERN = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    #contactButton;
    #closeContactModalButton;

    #contactForm;

    #emailNameInput;
    #emailInput;
    #emailContactTypeOptions;
    #emailMessage;
    #emailSubmitButton;

    #contactModalContainer;
    #contactModalOverlay;

    #successToast;
    #successToastText;

    #errorToast;
    #errorToastText;

    constructor(autoInit = true) {

        this.#contactButton = document.getElementById('contactButton');
        this.#closeContactModalButton = document.getElementById('close-contact-modal');

        this.#contactForm = document.getElementById('contactForm');

        this.#emailNameInput = document.getElementById('email-name-input');
        this.#emailInput = document.getElementById('email-email-input');
        this.#emailContactTypeOptions = document.getElementById('email-contact-type-options');
        this.#emailMessage = document.getElementById('email-message-input');
        this.#emailSubmitButton = document.getElementById('email-submit-button');

        this.#contactModalContainer = document.getElementById('contactModal');
        this.#contactModalOverlay = document.getElementById('contactModalOverlay');

        this.#successToast = document.getElementById('toast-success');
        this.#successToastText = document.getElementById('toast-success-text');

        this.#errorToast = document.getElementById('toast-danger');
        this.#errorToastText = document.getElementById('toast-danger-text');

        // Bind the validateEmailForm method to the correct context
        this.validateEmailForm = this.validateEmailForm.bind(this);

        if (autoInit === true) {
            this.init();
        }
    }

    init() {
        this.#initValidatorListeners();
        this.#initFormSubmitListener();
        this.#initFormModalListeners();
    }

    // MARK: Event Listeners ---------------------------

    #initValidatorListeners() {
        this.#emailNameInput.addEventListener('input', this.validateEmailForm);
        this.#emailInput.addEventListener('input', this.validateEmailForm);
        this.#emailMessage.addEventListener('input', this.validateEmailForm);
    }

    #initFormModalListeners() {
        this.#contactButton.addEventListener('click', () => {
            this.#contactModalContainer.classList.remove('hidden');
        });

        this.#closeContactModalButton.addEventListener('click', () => {
            this.resetForm();
            this.#contactModalContainer.classList.add('hidden');
        });

        this.#contactModalOverlay.addEventListener('click', (event) => {
            if (event.target.id === this.#contactModalOverlay.id) {
                this.resetForm();
                this.#contactModalContainer.classList.add('hidden');
            }
        });
    }

    #initFormSubmitListener() {
        this.#contactForm.addEventListener('submit', async (event) => {
            event.preventDefault();

            try {
                this.#handleFormSubmit(event);

            } catch (e) {
                console.error(e);
                document.getElementById('errorModal').classList.remove('hidden');
                return;
            }
        });
    }

    // MARK: Business Logic ----------------------------

    resetForm() {
        this.#contactForm.reset();
        this.#emailSubmitButton.disabled = true;
    }

    validateEmailForm() {
        if (this.#emailNameInput.value.length > 0 && this.#emailInput.value.length > 0 && this.#emailMessage.value.length > 0 && EmailFormController.#EMAIL_PATTERN.test(this.#emailInput.value)) {
            this.#emailSubmitButton.disabled = false;
        }

        if (this.#emailNameInput.value.length === 0 || this.#emailInput.value.length === 0 || this.#emailMessage.value.length === 0 || !EmailFormController.#EMAIL_PATTERN.test(this.#emailInput.value)) {
            this.#emailSubmitButton.disabled = true;
        }
    }

    #handleFormSubmit(event) {

        const recaptchaResponse = grecaptcha.getResponse();

        if (!recaptchaResponse) {
            this.showErrorToast('reCAPTCHA zaznamenal chybu. Prosím, zkuste to znovu.');
            return;
        }

        const formData = new FormData(event.target);
        formData.append('g-recaptcha-response', recaptchaResponse);
        const data = Object.fromEntries(formData.entries());

        const selectedContactType = this.#emailContactTypeOptions.value || 'N/A';
        data.message += `\n\nTyp kontaktu: ${selectedContactType}`;

        fetch('https://be.cistystart.cz/send-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(_ => {
                this.showSuccessToast('Email byl úspěšně odeslán.');
                this.hideFormModal();
            })
            .catch((_) => {
                this.showErrorToast('Něco se pokazilo. Zkuste to prosím znovu.');
            });
    }

    // MARK: UI Helpers --------------------------------

    showSuccessToast(text) {
        this.#successToastText.innerText = text;

        this.#successToast.classList.remove('hidden');
        setTimeout(() => {
            this.#successToast.classList.add('hidden');
        }, 5000);
    }

    showErrorToast(text) {
        this.#errorToastText.innerHTML = text;

        this.#errorToast.classList.remove('hidden');
        setTimeout(() => {
            this.#errorToast.classList.add('hidden');
        }, 5000);
    }

    hideErrorToast() {
        this.#errorToast.classList.add('hidden');
    }

    hideSuccessToast() {
        this.#successToast.classList.add('hidden');
    }

    hideFormModal() {
        this.#contactModalContainer.classList.add('hidden');
        this.resetForm();
    }
}