import { isDev, windowObject } from '../index.js';

export class CookieBarController {
    
    static #COOKIE_CONSENT = 'cookieConsent';

    #cookieBarContainer;
    #cookieBarRejectButton;
    #cookieBarAcceptButton;
    
    constructor(autoInit = true) {
        this.#cookieBarContainer = document.getElementById('cookie-bar-area');
        this.#cookieBarRejectButton = document.getElementById('cookie-bar-reject-button');
        this.#cookieBarAcceptButton = document.getElementById('cookie-bar-accept-button');

        this.checkCookieConsent();

        if (autoInit === true) {
            this.init();
        }
    }

    init() {
        this.checkCookieConsent();
        this.setupEventListeners();
    }
    
    // CHECK COOKIE CONSENT
    checkCookieConsent() {
        const cookieConsent = localStorage.getItem(CookieBarController.#COOKIE_CONSENT);
        console.log('Cookie consent from local storage: ', cookieConsent);
        
        if (cookieConsent == 0 || cookieConsent == 1) {
            console.log('User has already made a choice');
            this.#hideCookieBar();
            this.#sKlikRun(cookieConsent);
        }

    }

    // HELPERS

    #hideCookieBar() {
        console.log('Hiding cookie bar');
        this.#cookieBarContainer.classList.add('hidden');
    }

    // SETUP EVENT LISTENERS
    setupEventListeners() {
        this.#cookieBarRejectButton.addEventListener('click', this.#handleRejection.bind(this));
        this.#cookieBarAcceptButton.addEventListener('click', this.#handleAcceptance.bind(this));
    }

    // HANDLERS 
    #handleRejection() {
        console.log('User rejected cookies');
        localStorage.setItem(CookieBarController.#COOKIE_CONSENT, 0);
        this.#hideCookieBar();
        this.#sKlikRun(0);
    }

    #handleAcceptance() {
        console.log('User accepted cookies');
        localStorage.setItem(CookieBarController.#COOKIE_CONSENT, 1);
        this.#hideCookieBar();
        this.#sKlikRun(1);
    }

    #sKlikRun(acceptanceState = 0) {

        if (isDev) {
            return;
        }

        console.log('Running sKlik with acceptance state: ', acceptanceState);

        windowObject.sznIVA.IS.updateIdentities({
            eid: null,
        });

        var retargetingConf = {
            rtgId: 613476,
            consent: acceptanceState,
        };

        windowObject.rc.retargetingHit(retargetingConf);
    }

}