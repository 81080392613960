import 'tailwindcss/tailwind.css';
import 'flowbite';
import './styles.css';
import { EmailFormController } from './src/email_form_controller';
import { MapInitializationController } from './src/google_map_initialization_controller';
import { CookieBarController } from './src/cookie_bar_controller';

export const isDev = typeof window !== 'undefined' && window.location.host !== 'cistystart.cz';
export const windowObject = typeof window !== 'undefined' ? window : {};

var menuOpen = false;

function initGoogleAnalytics() {

    if (isDev) {
        return;
    }

    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', 'G-MEY82SY4LD');
}

function initMenuListener() {

    const menuButton = document.getElementById('menu-button');
    const mobileMenu = document.getElementById('mobile-menu');

    menuButton.addEventListener('click', () => {
        menuOpen = !menuOpen;
        mobileMenu.classList.toggle('hidden');
        menuButton.innerHTML = menuOpen ?
            `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#012B6A"><path d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z"/></svg>` :
            `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#012B6A"><path d="M160-240q-17 0-28.5-11.5T120-280q0-17 11.5-28.5T160-320h640q17 0 28.5 11.5T840-280q0 17-11.5 28.5T800-240H160Zm0-200q-17 0-28.5-11.5T120-480q0-17 11.5-28.5T160-520h640q17 0 28.5 11.5T840-480q0 17-11.5 28.5T800-440H160Zm0-200q-17 0-28.5-11.5T120-680q0-17 11.5-28.5T160-720h640q17 0 28.5-11.5T840-680q0 17-11.5 28.5T800-640H160Z"/></svg>`;
    });
}


function updateURLonIntersection() {
    const sections = document.querySelectorAll('section');
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5
    };

    const observer = new IntersectionObserver((entries, _) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          history.replaceState(null, null, `#${entry.target.id}`);
        }
      });
    }, options);

    sections.forEach(section => {
      observer.observe(section);
    });
}


// ================================================================
// MAIN FUNCTION -> Runs when the page is loaded
// ================================================================
document.addEventListener('DOMContentLoaded', (_) => {

    initGoogleAnalytics();
    initMenuListener();

    updateURLonIntersection();


    // new CookieBarController();
    new EmailFormController();
    new MapInitializationController("AIzaSyD_nxiznIuXCr8r1BXo2eRnE68bL0Xq_pg", "google_map");

});