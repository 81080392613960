import { Loader } from "@googlemaps/js-api-loader"

export class MapInitializationController {
    constructor(apiKey, mapElementId) {
        this.apiKey = apiKey;
        this.mapElementId = mapElementId;
        this.loader = new Loader({
            apiKey: this.apiKey,
            version: "weekly",
            libraries: ["marker"],
        });

        this.initMap = this.initMap.bind(this);

        this.initMap();
    }

    async initMap() {
        const { Map } = await this.loader.importLibrary("maps");
        const { AdvancedMarkerElement, PinElement } = await this.loader.importLibrary("marker");

        this.map = new Map(document.getElementById(this.mapElementId), {
            center: { lat: 49.1965675, lng: 16.6261886 },
            zoom: 15,
            mapId: 'e3dcd9dfa0584244',
        });

        const pinScaled = new PinElement({
            scale: 1.6,
            glyphColor: 'white',
        });

        this.marker = new AdvancedMarkerElement({
            position: { lat: 49.1965675, lng: 16.6261886 },
            map: this.map,
            title: '',
            content: pinScaled.element,
        });

        this.infoWindow = new google.maps.InfoWindow({
            disableAutoPan: true,
            pixelOffset: new google.maps.Size(0, 25),
            closeBoxURL: '',
            isHidden: false,
            pane: 'floatPane',
            enableEventPropagation: false,
            content: `
                <a href="https://g.co/kgs/zgPfAiW" target="_blank">
                    <div style="font-weight: 900; padding-bottom:">
                    ČistýStart | Mediační kancelář
                    </div>
                    Plynárenská 1, Brno-střed
                </a>
            `,
        });

        this.infoWindow.open(this.map, this.marker);

        this.marker.addListener('click', () => {
            this.infoWindow.open(this.map, this.marker);
        });
    }
}